<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <bo-card v-if="isList" title="Legal Page" use-table>
        <template #filters>
          <b-form-row class="justify-content-end">
            <b-col lg="5">
              <bo-search />
            </b-col>
          </b-form-row>
        </template>
        <b-table
          :fields="legalFields"
          :items="legalData"
          responsive
          bordered
        >
          <template #cell(num)="data">
            {{ data.index += 1 }}
          </template>
          <template #cell(action)="data">
            <b-button
              variant="outline-info"
              class="btn-icon"
              size="sm"
              v-b-tooltip.hover="'Edit'"
              :to="{ name: $route.name, params: { slug: data.index+1 } }"
            >
              <i class="ti-marker-alt"></i>
            </b-button>
          </template>
        </b-table>
      </bo-card>
      <b-card no-body v-else>
        <template #header>
          <b-card-title title-tag="h5">Update Legal</b-card-title>
        </template>
        <b-form @submit.prevent="handleLegalSubmit($route.params.slug - 1)">
          <b-card-body>
            <b-row>
              <b-col lg="6">
                <b-form-group label-for="legalName">
                  <template #label>Legal Name <span class="text-danger mr5">*</span></template>
                  <b-form-input 
                    id="legalName"
                    v-model="legalRow.name"
                    placeholder="e.g Privacy Policy"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12">
                <b-form-group label-for="legalContent">
                  <template #label>Content<span class="text-danger mr5">*</span></template>
                  <CKEditor
                    id="legalContent"
                    :value="legalRow.content"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <div class="text-right">
              <b-button variant="transparent" class="btn-rounded mr-3" @click="cancelAddUpdate()">Cancel</b-button>
              <b-button type="submit" variant="success" class="btn-rounded">Submit</b-button>
            </div>
          </b-card-footer>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import CKEditor from '../components/CKEditor.vue'
import GlobalVue from '../libs/Global.vue'

export default {
  components: { CKEditor },
  extends: GlobalVue,
  mounted() {
    this.loadLegalData()
  },  
  data() {
    return {
      rows: 30,
      perPage: 1,
      currentPage: 5,
      value: '',
      value2: '',
      legalData: [],
      legalRow: {},
      legalFields: [
        { key: 'num', label: '#' },
        { key: 'name', label: 'Legal Name' },
        'action',
      ]
    }
  },
  computed: {
    dataSrc() {
      return this.dummyData.legal
    },
  },
  methods: {
    loadLegalData() {
      this.dataSrc.forEach(el => {
        this.legalData.push(el)
      })
    },
    handleLegalSubmit(key) {
      if (key) {
        this.legalData.splice(key, 1, this.legalRow)
        this.confirmChanges('update', 'Legal')
      } else {
        this.legalData.push(this.legalRow)
        this.confirmChanges('add', 'Legal')
      }
      this.$nextTick(() => this.$router.push({ name: 'BoLegal' }))
    },
  },
  watch: {
    $route() {
      if (this.$route.params.slug) {
        this.legalRow = this.legalData[this.$route.params.slug - 1]
      }
    }
  }
}
</script>